import HeaderNew from "../components/HeaderNew";
import Footer from "../components/Footer";
import { useState } from "react";
import { toast } from "react-toastify";

const Login = () => {

    const [isChecked, setIsChecked] = useState(false);

    const handleLogin = () => {
        toast.error("Invalid details! Please reach out to support if you are locked out.");
    }


    return (
        <>
            <HeaderNew />
            <div className="container">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 my-5" style={{ minHeight: "550px" }}>
                        <div className="d-flex align-items-center justify-content-between">
                            <h1 className="fs-4">Login</h1>
                            <div>
                                <input type="checkbox"
                                    checked={isChecked}
                                    className="form-check-input"
                                    onChange={(e) => setIsChecked(e.target.checked)} /> Login as root?
                            </div>
                        </div>
                        <hr />

                        {
                            !isChecked &&
                            <>
                                <label className="">Account ID</label>
                                <input type="text" className="form-control" placeholder="Enter Account ID" />
                            </>
                        }


                        {
                            isChecked ?
                                <>
                                    <label className="mt-3">Root Email ID</label>
                                    <input type="text" className="form-control" placeholder="Enter Root Email ID" />
                                </>
                                :
                                <>
                                    <label className="mt-3">Email ID</label>
                                    <input type="text" className="form-control" placeholder="Enter Email ID" />
                                </>
                        }

                        <label className="mt-3">Password</label>
                        <input type="password" className="form-control" placeholder="Enter Password" />

                        <button className="btn btn-primary mt-3 w-100" onClick={handleLogin}>Login</button>

                        <div className="mt-5">
                            <p className="text-primary fs-6"><sup>*</sup> As of now, Duck Cloud is an invite only platform. Please reach out to <a href="mailto:team@duckcloud.tech">team@duckcloud.tech</a> if you are interested in using Duck Cloud.</p>
                        </div>
                    </div>
                    <div className="col-lg-4"></div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Login;