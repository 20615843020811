import HeaderNew from "../components/HeaderNew";
import { FaAngleDoubleDown } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import Footer from "../components/Footer";
import { FaExternalLinkAlt } from "react-icons/fa";

const Home = () => {

    return (
        <>
            <HeaderNew />
            <div className="container-fluid" style={{ backgroundColor: "#5800bd" }}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col text-center">
                            <h1 className="display-2 fw-bold customtxt" style={{ color: "#70ff54" }}>Cut Your Cloud Costs By 50% - Guaranteed <span className="text-white">or We'll Pay the Difference!</span></h1>
                            <h2 className="fs-2 fw-normal my-4 text-white"><FaAngleDoubleDown /> Watch how <FaAngleDoubleDown /></h2>
                            {/* <p className="m-0 p-0 text-white fs-6">Watch 60 seconds video to know more</p> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            {/* <img src="/assets/images/demo-thumbnail.png" style={{}} className="img img-fluid w-100" /> */}
                            <div className="ratio ratio-16x9">
                                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/1CRQMYuTQsI?rel=0&si=8DOKoVa4cebOTVOE" title="Cut cloud costs by 50%" frameBorder="0" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                    <div className="row py-5">
                        <div className="col-12 text-center">
                            <a href="https://calendly.com/team-duckcloud/30min" target="_blank" rel="noreferrer" className="mainbtn">Book Free Consultation <FaAnglesRight /></a>
                        </div>
                    </div>
                    <div className="row pt-2 pb-5 d-block d-lg-none">
                        <div className="col-12 text-center">
                            <a href="/savings-calculator" className="text-white">Calculate Savings <FaExternalLinkAlt /></a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home;