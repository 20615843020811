import { FaRegCopy } from "react-icons/fa";
import { copyEmailToClipboard } from "../utils/EmailUtils";

const Footer = () => {

    return (

        <>
            <div className="container-fluid bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-white py-5 text-center text-lg-start d-flex items-align-center justify-content-between">
                            <a className="text-white text-decoration-none" href="/login">Login</a>
                            <img src="/assets/images/white-duck.png" style={{ width: "30px" }} />

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col my-2 fs-6">
                            <div className="d-flex align-items-center justify-content-center">
                                <a className="text-black me-1 text-decoration-none" href="mailto:team@duckcloud.tech">
                                    <span className="fw-bold">24x7 Support:</span> team@duckcloud.tech
                                </a>
                                <FaRegCopy className="text-black" style={{ cursor: "pointer" }} onClick={copyEmailToClipboard} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;