const prices = [
    {
        "serviceName": "Compute",
        "types": [
            {
                typeName: "1 CPU - 2 GB RAM",
                metaData: "Storage: 20 GB",
                perMonthPriceAWS: 14.73,
                perMonthPriceDuckCloud: 9.16
            },
            {
                typeName: "2 CPU - 4 GB RAM",
                metaData: "Storage: 100 GB",
                perMonthPriceAWS: 29,
                perMonthPriceDuckCloud: 18.33
            },
            {
                typeName: "4 CPU - 8 GB RAM",
                metaData: "Storage: 200 GB",
                perMonthPriceAWS: 57.26,
                perMonthPriceDuckCloud: 37.51
            },
            {
                typeName: "8 CPU - 32 GB RAM",
                metaData: "Storage: 500 GB",
                perMonthPriceAWS: 138,
                perMonthPriceDuckCloud: 91.71
            }
        ]
    },
    {
        "serviceName": "Database",
        "types": [
            {
                typeName: "MariaDB/MySQL/Postgres/MongoDB : 1 CPU - 2 GB RAM",
                metaData: "Storage: 20 GB",
                perMonthPriceAWS: 14.73,
                perMonthPriceDuckCloud: 9.16
            },
            {
                typeName: "MariaDB/MySQL/Postgres/MongoDB : 2 CPU - 4 GB RAM",
                metaData: "Storage: 100 GB",
                perMonthPriceAWS: 29,
                perMonthPriceDuckCloud: 18.33
            },
            {
                typeName: "MariaDB/MySQL/Postgres/MongoDB : 4 CPU - 8 GB RAM",
                metaData: "Storage: 200 GB",
                perMonthPriceAWS: 57.26,
                perMonthPriceDuckCloud: 37.51
            },
            {
                typeName: "MariaDB/MySQL/Postgres/MongoDB : 8 CPU - 32 GB RAM",
                metaData: "Storage: 500 GB",
                perMonthPriceAWS: 138,
                perMonthPriceDuckCloud: 91.71
            }
        ]
    },
    {
        "serviceName": "Load Balancer",
        "types": [
            {
                typeName: "Up to 1 GB Per Month",
                perMonthPriceAWS: 15,
                perMonthPriceDuckCloud: 10
            },
            {
                typeName: "Up to 1 GB Per Hour",
                perMonthPriceAWS: 29,
                perMonthPriceDuckCloud: 19
            },
            {
                typeName: "Up to 1 TB Per Month",
                perMonthPriceAWS: 29,
                perMonthPriceDuckCloud: 19
            },
            {
                typeName: "Up to 1 TB Per Hour",
                perMonthPriceAWS: 15000,
                perMonthPriceDuckCloud: 10000
            },
        ]
    },
    {
        "serviceName": "Object Storage",
        "types": [
            {
                typeName: "Per 1 TB Storage",
                perMonthPriceAWS: 118,
                perMonthPriceDuckCloud: 99
            }
        ]
    },
    {
        "serviceName": "VPC/VPN",
        "types": [
            {
                typeName: "Per Private Tunnel",
                perMonthPriceAWS: 348,
                perMonthPriceDuckCloud: 150
            }
        ]
    },
    {
        "serviceName": "CDN",
        "types": [
            {
                typeName: "Global Per GB",
                perMonthPriceAWS: 2.2,
                perMonthPriceDuckCloud: 1.2
            }
        ]
    },
    {
        "serviceName": "Block Storage/Backup",
        "types": [
            {
                typeName: "Per 1 TB Storage",
                perMonthPriceAWS: 118,
                perMonthPriceDuckCloud: 99
            }
        ]
    },
    {
        "serviceName": "Security",
        "types": [
            {
                typeName: "SSL Certificates",
                perMonthPriceAWS: 400,
                perMonthPriceDuckCloud: 50
            },
            {
                typeName: "Key Management",
                perMonthPriceAWS: 47,
                perMonthPriceDuckCloud: 38
            }
        ]
    },
    {
        "serviceName": "DNS Management",
        "types": [
            {
                typeName: "Per Domain",
                perMonthPriceAWS: 0.8,
                perMonthPriceDuckCloud: 0.5
            }
        ]
    },
];

export { prices };