import Modal from 'react-modal';
import { IoMdCloseCircle } from "react-icons/io";
import { prices } from '../../service/prices';
import { useEffect, useState } from 'react';
import { addService, readDetails } from '../../service/SavingsCalculatorService';


const SavingsCalculatorSelectModal = ({ modalIsOpen, setModalIsOpen }) => {

    const [selectedService, setSelectedService] = useState();
    const [selectedType, setSelectedType] = useState();
    const [instanceCount, setInstanceCount] = useState(1);

    const [serviceTypes, setServiceTypes] = useState();

    useEffect(() => {
        resetSelection();
    }, [modalIsOpen]);

    useEffect(() => {
        console.log("Service : ", selectedService);
        console.log("Type : ", selectedType);
        fetchServiceTypes();
    }, [selectedService, selectedType]);

    const fetchServiceTypes = () => {
        if (!selectedService) return null;
        prices.forEach(p => {
            if (p.serviceName === selectedService) {
                setServiceTypes(p.types);
                return;
            }
        });
    }

    const addToEstimate = () => {
        var typeDetails = null;
        serviceTypes.forEach(t => {
            if (t.typeName === selectedType) {
                typeDetails = t;
            }
        })

        addService(selectedService, typeDetails, instanceCount);
        setModalIsOpen(false);

        resetSelection();
    }

    const resetSelection = () => {
        console.log("Resetting...");
        setSelectedService(null);
        setSelectedType(null);
        setServiceTypes(null);
        setInstanceCount(1);
    }

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Service Selection Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        color: 'black',
                        width: '80%',
                        height: "80%",
                        margin: 'auto',
                        padding: '24px',
                    }
                }}
            >
                <div className="d-flex items-align-center justify-content-between">
                    <h2 className="m-0 p-0">Add Service</h2>
                    <button onClick={() => setModalIsOpen(false)} className="btn btn-warning text-black">Close <IoMdCloseCircle /></button>
                </div>
                <div className="mt-4">
                    <p className="m-0 p-0">Select service</p>
                    <select className="form-select" onChange={(e) => setSelectedService(e.target.value)}>
                        <option value="-1" key="-1">Select an option</option>
                        {
                            prices?.map((p, i) => (
                                <option value={p.serviceName} key={i}>
                                    {p.serviceName}
                                </option>
                            ))
                        }
                    </select>
                </div>
                {selectedService && serviceTypes &&
                    <div className="mt-4">
                        <p className="m-0 p-0">Select type</p>
                        <select className="form-select" onChange={(e) => setSelectedType(e.target.value)}>
                            <option value="-1" key="-1">Select an option</option>
                            {
                                serviceTypes?.map((t, i) => (
                                    <option value={t.typeName} key={i}>
                                        {t.typeName}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                }
                {selectedType && selectedType !== "-1" &&
                    <div className="mt-4">
                        <p className="m-0 p-0">Number of instances</p>
                        <input className="form-control" defaultValue={1}
                            onChange={(e) => setInstanceCount(e.target.value)}
                        />
                    </div>
                }
                {selectedType && selectedType !== "-1" &&
                    <div className="mt-4 text-end">
                        <button className="btn btn-warning text-black"
                            onClick={() => addToEstimate()}
                        >Add to estimate</button>
                    </div>
                }
            </Modal>
        </>
    )
}

export default SavingsCalculatorSelectModal;