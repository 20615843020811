import { toast } from "react-toastify";

const copyEmailToClipboard = () => {
    const email = "team@duckcloud.tech";

    navigator.clipboard.writeText(email).then(function () {
        toast('Email copied!');
    }).catch(function (err) {
        console.error('Error copying text: ', err);
    });
}

export { copyEmailToClipboard };