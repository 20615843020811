const addService = (service, type, instance = 1) => {
    console.log("Adding record ", service, " type ", type, " instance ", instance);
    var details = readDetails();
    var record = {
        "service": service,
        "type": type,
        "instance": instance
    };
    details.push(record);
    saveDetails(details);
}

const removeService = (index) => {
    console.log("Remvoing index : ", index);
    var details = readDetails();
    details.splice(index, 1);
    saveDetails(details);
}
const saveDetails = (details) => {
    localStorage.setItem('estimate', JSON.stringify(details));
}

const readDetails = () => {
    const storedArray = localStorage.getItem('estimate');
    if (!storedArray) {
        return [];
    }
    const parsedArray = JSON.parse(storedArray);
    console.log("Returning ", parsedArray);
    return parsedArray;
}

export { addService, removeService, saveDetails, readDetails };