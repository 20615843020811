import HeaderNew from "../components/HeaderNew";
import Footer from "../components/Footer";
import { IoMdAddCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import SavingsCalculatorSelectModal from "../components/modals/SavingsCalculatorSelectModal";
import { readDetails, removeService } from "../service/SavingsCalculatorService";
import { MdDeleteOutline } from "react-icons/md";

const SavingsCalculator = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [details, setDetails] = useState([]);

    const [awsTotal, setAwsTotal] = useState();
    const [duckTotal, setDuckTotal] = useState();

    useEffect(() => {
        loadEstimate();
        calculateSavings();
    }, [modalIsOpen, details]);

    const loadEstimate = () => {
        var newDetails = readDetails();
        if (!newDetails || newDetails.length === 0) {
            if (details.length !== 0) {
                setDetails([]);
            }
            return;
        }
        if (newDetails.length === details.length) {
            return;
        }
        setDetails(newDetails);
    }

    const calculateSavings = () => {
        var aws = 0, duck = 0;

        if (!details || details.length === 0) {
            setAwsTotal(null);
            setDuckTotal(null);
        }

        details.forEach(d => {
            if (d.type.perMonthPriceAWS) {
                aws = aws + d.type.perMonthPriceAWS * d.instance;
            }

            if (d.type.perMonthPriceDuckCloud) {
                duck = duck + d.type.perMonthPriceDuckCloud * d.instance;
            }

            setAwsTotal(aws);
            setDuckTotal(duck);
        })
    }

    const removeServiceFromEstimate = (index) => {
        removeService(index);
        loadEstimate();
    }

    return (
        <>
            <HeaderNew />
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <h1 className="fs-4 m-0 p-0 text-center text-lg-start">Savings Calculator</h1>
                    </div>
                    <div className="col-lg-6 text-center text-lg-end my-3 my-lg-0">
                        <button className="btn btn-warning text-black"
                            onClick={() => setModalIsOpen(true)}>
                            Add Service To Estimate <IoMdAddCircle />
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{ minHeight: "400px" }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="">
                                        <p className="m-0 p-0 fw-bold">Service</p>
                                        <p style={{ fontSize: "10px" }} className="m-0 p-0">Description</p>
                                    </th>
                                    <th className="text-end p-0 m-0">
                                        <p className="m-0 p-0 fw-bold">Cost</p>
                                        <p style={{ fontSize: "10px" }} className="m-0 p-0">Per Instance Per Month</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    details?.map((item, index) => (
                                        <>
                                            <tr key={index}>
                                                <td>
                                                    <p style={{ cursor: "pointer" }}
                                                        onClick={() => removeServiceFromEstimate(index)}
                                                        className="text-danger"
                                                    ><MdDeleteOutline /></p>
                                                </td>
                                                <td>
                                                    <p className="m-0 p-0 text-black fs-6">{item.service} x {item.instance}</p>
                                                    <p className="m-0 p-0 text-secondary" style={{ fontSize: "10px" }}>{item.type.typeName}</p>
                                                </td>
                                                <td className="text-end" style={{ fontSize: "12px" }}>
                                                    <p className="m-0 p-0 text-danger">Single Vendor ${item.type.perMonthPriceAWS}</p>
                                                    <p className="m-0 p-0 text-success">Via Duck Cloud ${item.type.perMonthPriceDuckCloud}</p>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {awsTotal && duckTotal && awsTotal !== 0 && duckTotal !== 0 &&
                    <div className="row mb-5" style={{ minHeight: "300px" }}>
                        <div className="col-lg-4">
                            <p className="text-center bg-danger text-white p-2 m-0 mt-2">Cost Via Single Vendor</p>
                            <p className="d-flex items-align-center justify-content-between m-0 p-0 text-danger">
                                <span>Per month</span>
                                <span>${(awsTotal).toFixed(2)}</span>
                            </p>
                            <p className="d-flex items-align-center justify-content-between m-0 p-0 text-danger">
                                <span>Per year</span>
                                <span>${(awsTotal * 12).toFixed(2)}</span>
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <p className="text-center bg-primary text-white p-2 m-0 mt-2">Cost Via Duck Cloud</p>
                            <p className="d-flex items-align-center justify-content-between m-0 p-0 text-success">
                                <span>Per month</span>
                                <span>${(duckTotal).toFixed(2)}</span>
                            </p>
                            <p className="d-flex items-align-center justify-content-between m-0 p-0 text-success">
                                <span>Per year</span>
                                <span>${(duckTotal * 12).toFixed(2)}</span>
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <p className="text-center bg-success text-white p-2 m-0 mt-2">Savings</p>
                            <p className="d-flex items-align-center justify-content-between m-0 p-0 fw-bold text-success">
                                <span>Per month</span>
                                <span>{((awsTotal - duckTotal) / (awsTotal) * 100).toFixed(2)}%</span>
                            </p>
                            <p className="d-flex items-align-center justify-content-between m-0 p-0 fw-bold text-success">
                                <span>Per year</span>
                                <span>{((awsTotal - duckTotal) / (awsTotal) * 100 * 1.05).toFixed(2)}%</span>
                            </p>
                        </div>
                    </div>
                }

            </div >
            <Footer />
            <SavingsCalculatorSelectModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </>
    )
}

export default SavingsCalculator;