import { FaRegCopy } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";

const HeaderNew = () => {

    return (
        <>
            {/* <div className="container-fluid bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col my-2" style={{ fontSize: "13px" }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <a className="text-black me-1 text-decoration-none" href="mailto:team@duckcloud.tech">
                                    Support: <span className="fw-bold">team@duckcloud.tech</span>
                                </a>
                                <FaRegCopy className="text-black" style={{ cursor: "pointer" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container-fluid bg-black py-4">
                <div className="container">
                    <div className="row d-flex align-items-center justify-content-between">
                        <div className="col-lg-6 text-center text-lg-start">
                            <a className="" href="/">
                                <img src="/assets/images/duck-cloud-logo-white.png" style={{ width: "150px", height: "auto" }} />
                            </a>
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="d-flex align-items-center justify-content-end">
                                <a className="text-white d-none d-lg-block me-1 text-decoration-none" href="/savings-calculator">
                                    Calculate Savings <FaExternalLinkAlt />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderNew;